<template>
  <div class="vehicle-insert-modal">
    <v-card elevation="3" class="vehicle-basic-modal">
      <span class="vehicle-header-modal">Details</span>
      <div class="text-field-modal">
        <div class="text-field-blk">
          <v-text-field
            v-model="vehicleNo"
            label="Vehicle No"
            placeholder="Vehicle No"
            outlined
            dense
            :error-messages="vehicleNoError"
            @blur="$v.vehicleNo.$touch()"
            @input="$v.vehicleNo.$touch()"
            @keydown="nameKeydown($event)"
            @paste="pasteKey($event)"
            @keydown.space.prevent></v-text-field>
        </div>
        <div class="text-field-blk">
          <v-autocomplete
            v-model="manufacturer"
            label="Manufacturer"
            placeholder="Manufacturer"
            outlined
            dense
            :items="filterData.manufacturer"
            return-object
            item-text="name"
            :error-messages="manufacturerError"
            @blur="$v.manufacturer.$touch()"
            @input="$v.manufacturer.$touch()"></v-autocomplete>
        </div>
        <div class="text-field-blk">
          <v-autocomplete
            v-model="model"
            label="Model"
            placeholder="Model"
            outlined
            dense
            :items="manufacturer.model"
            return-object
            item-text="name"
            :error-messages="modelError"
            @blur="$v.model.$touch()"
            @input="$v.model.$touch()"></v-autocomplete>
        </div>
        <div class="text-field-blk">
          <v-autocomplete
            v-model="color"
            label="Color"
            placeholder="Color"
            outlined
            dense
            :items="filterData.color"
            return-object
            item-text="name"
            :error-messages="colorError"
            @blur="$v.color.$touch()"
            @input="$v.color.$touch()"></v-autocomplete>
        </div>
        <div class="text-field-blk">
          <v-autocomplete
            v-model="year"
            label="Year"
            placeholder="Year"
            outlined
            dense
            return-object
            item-text="name"
            :items="yearData"
            :error-messages="yearError"
            @blur="$v.year.$touch()"
            @input="$v.year.$touch()"></v-autocomplete>
        </div>
        <div class="text-field-blk">
          <v-autocomplete v-model="fuel" label="Fuel Type" placeholder="Fuel Type" outlined dense :items="filterData.fuel" return-object item-text="name"></v-autocomplete>
        </div>
        <div class="text-field-blk">
          <v-menu v-model="InsDateModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="InsExpiryDate" outlined label="Insurance Expiry" readonly dense v-on="on"> </v-text-field>
            </template>
            <v-date-picker @input="InsDateModel = false" :min="maxDate" v-model="InsExpiryDate" :readonly="vehicleDetails.trash" color="black"></v-date-picker>
          </v-menu>
        </div>
        <div class="text-field-blk">
          <v-menu v-model="TaxExpiryModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="TaxExpiryDate" outlined label="Tax Validity Expiry" readonly dense v-on="on"> </v-text-field>
            </template>
            <v-date-picker @input="TaxExpiryModel = false" :min="maxDate" v-model="TaxExpiryDate" :readonly="vehicleDetails.trash" color="black"></v-date-picker>
          </v-menu>
        </div>
        <div class="text-field-blk">
          <v-menu v-model="RCExpiryModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="RCExpiryDate" outlined label="RC Expiry" readonly dense v-on="on"> </v-text-field>
            </template>
            <v-date-picker @input="RCExpiryModel = false" :min="maxDate" v-model="RCExpiryDate" :readonly="vehicleDetails.trash" color="black"></v-date-picker>
          </v-menu>
        </div>
        <div class="text-field-blk">
          <v-menu v-model="PmtExpiryModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="PmtExpiryDate" outlined label="Permit Expiry" readonly dense v-on="on"> </v-text-field>
            </template>
            <v-date-picker @input="PmtExpiryModel = false" :min="maxDate" v-model="PmtExpiryDate" :readonly="vehicleDetails.trash" color="black"></v-date-picker>
          </v-menu>
        </div>
        <div class="text-field-blk">
          <v-menu v-model="FtsExpiryModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="FtsExpiryDate" outlined label="Fitness Certificate Expiry" readonly dense v-on="on"> </v-text-field>
            </template>
            <v-date-picker @input="FtsExpiryModel = false" :min="maxDate" v-model="FtsExpiryDate" :readonly="vehicleDetails.trash" color="black"></v-date-picker>
          </v-menu>
        </div>
        <div class="text-field-blk">
          <v-menu v-model="PlnExpiryModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="PlnExpiryDate" outlined label="PUC Expiry" readonly dense v-on="on"> </v-text-field>
            </template>
            <v-date-picker @input="PlnExpiryModel = false" :min="maxDate" v-model="PlnExpiryDate" :readonly="vehicleDetails.trash" color="black"></v-date-picker>
          </v-menu>
        </div>
        <div class="button-blk">
          <v-btn color="#eea004" @click="submitDetails">{{ buttonName }}</v-btn>
          <v-btn color="#ed5e5e" @click="cancelSubmit">Cancel</v-btn>
        </div>
      </div>
    </v-card>
    <Loading v-if="loading"></Loading>
    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import Confirm from "@/components/common/Confirm";
import moment from "moment";
import VehicleDocument from "@/components/vehicle-management/vehicle-document/VehicleDocument";
export default {
  name: "VehicleInsert",
  components: { Confirm, Loading, VehicleDocument },
  props: {
    tabName: String,
    vehicleDetails: Object,
    editStatus: Boolean,
  },
  mixins: [validationMixin],
  validations() {
    return {
      vehicleNo: { required },
      manufacturer: { required },
      model: { required },
      color: { required },
      year: { required },
    };
  },
  data: () => {
    return {
      vehicleNo: "",
      manufacturer: "",
      model: "",
      color: "",
      year: "",
      fuel: "",
      filterData: "",
      loading: false,
      buttonName: "submit",
      vehicleId: "",
      documents: "",
      yearData: ["2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024"],
      InsDateModel: false,
      InsExpiryDate: null,
      TaxExpiryModel: false,
      TaxExpiryDate: null,
      RCExpiryModel: false,
      RCExpiryDate: null,
      PmtExpiryModel: false,
      PmtExpiryDate: null,
      FtsExpiryModel: false,
      FtsExpiryDate: null,
      PlnExpiryDate: null,
      PlnExpiryModel: false,
      maxDate: moment().format("YYYY-MM-DD"),
    };
  },
  mounted() {
    if (this.editStatus === true) {
      this.vehicleNo = this.vehicleDetails.vehicleno;
      this.manufacturer = this.vehicleDetails.details.manufacturer;
      this.model = this.vehicleDetails.details.model;
      this.color = this.vehicleDetails.details.color;
      this.year = this.vehicleDetails.details.year;
      if (this.vehicleDetails.details.fuel.length > 0) {
        this.fuel = this.vehicleDetails.details.fuel[0];
      } else {
        this.fuel = "";
      }
      this.RCExpiryDate = this.vehicleDetails.document.rcbook.expdate ? moment(this.vehicleDetails.document.rcbook.expdate).format("YYYY-MM-DD") : "";
      this.TaxExpiryDate = this.vehicleDetails.document.tax.expdate ? moment(this.vehicleDetails.document.tax.expdate).format("YYYY-MM-DD") : "";
      this.FtsExpiryDate = this.vehicleDetails.document.fitness.expdate ? moment(this.vehicleDetails.document.fitness.expdate).format("YYYY-MM-DD") : "";
      this.PlnExpiryDate = this.vehicleDetails.document.pollution.expdate ? moment(this.vehicleDetails.document.pollution.expdate).format("YYYY-MM-DD") : "";
      this.InsExpiryDate = this.vehicleDetails.document.insurance.expdate ? moment(this.vehicleDetails.document.insurance.expdate).format("YYYY-MM-DD") : "";
      this.PmtExpiryDate = this.vehicleDetails.document.carriagepermit.expdate ? moment(this.vehicleDetails.document.carriagepermit.expdate).format("YYYY-MM-DD") : "";
      this.buttonName = "Update";
      this.vehicleId = this.vehicleDetails.token;
    } else {
      this.resetVehicleDetails();
      this.buttonName = "submit";
      this.driverId = "";
    }
    this.getFilters();
  },
  watch: {
    manufacturer() {
      if (this.manufacturer === null) {
        this.manufacturer = "";
      }
    },
    tabName() {
      if (this.editStatus === true) {
        this.vehicleNo = this.vehicleDetails.vehicleno;
        this.manufacturer = this.vehicleDetails.details.manufacturer;
        this.model = this.vehicleDetails.details.model;
        this.color = this.vehicleDetails.details.color;
        this.year = this.vehicleDetails.details.year;
        if (this.vehicleDetails.details.fuel.length > 0) {
          this.fuel = this.vehicleDetails.details.fuel[0];
        } else {
          this.fuel = "";
        }
        this.RCExpiryDate = this.vehicleDetails.document.rcbook.expdate ? moment(this.vehicleDetails.document.rcbook.expdate).format("YYYY-MM-DD") : "";
        this.TaxExpiryDate = this.vehicleDetails.document.tax.expdate ? moment(this.vehicleDetails.document.tax.expdate).format("YYYY-MM-DD") : "";
        this.FtsExpiryDate = this.vehicleDetails.document.fitness.expdate ? moment(this.vehicleDetails.document.fitness.expdate).format("YYYY-MM-DD") : "";
        this.PlnExpiryDate = this.vehicleDetails.document.pollution.expdate ? moment(this.vehicleDetails.document.pollution.expdate).format("YYYY-MM-DD") : "";
        this.InsExpiryDate = this.vehicleDetails.document.insurance.expdate ? moment(this.vehicleDetails.document.insurance.expdate).format("YYYY-MM-DD") : "";
        this.PmtExpiryDate = this.vehicleDetails.document.carriagepermit.expdate ? moment(this.vehicleDetails.document.carriagepermit.expdate).format("YYYY-MM-DD") : "";
      } else {
        this.resetVehicleDetails();
        this.buttonName = "submit";
        this.driverId = "";
      }
    },
  },
  methods: {
    nameKeydown(e) {
      if (/[^A-Z0-9]/gi.test(e.key)) {
        e.preventDefault();
      }
    },
    pasteKey(event) {
      let main_text = event.clipboardData.getData("text");
      main_text = main_text.replace(/[^A-Z0-9]/gi, "");
      setTimeout(() => {
        this.vehicleNo = main_text;
      }, 0);
    },
    async getFilters() {
      const response = await axios.get("vehicle/filters");
      this.filterData = response.data;
    },
    resetVehicleDetails() {
      this.vehicleNo = "";
      this.manufacturer = "";
      this.model = "";
      this.color = "";
      this.fuel = "";
      this.year = "";
      this.RCExpiryDate = "";
      this.TaxExpiryDate = "";
      this.FtsExpiryDate = "";
      this.PlnExpiryDate = "";
      this.InsExpiryDate = "";
      this.PmtExpiryDate = "";
      this.expiryDate = moment().add(2, "years").format("YYYY-MM-DD");
      this.$v.$reset();
    },
    async submitDetails() {
      if (this.$v.$anyError) {
        this.$v.$touch();
      } else {
        if (!this.fuel) {
          this.fuel = { id: 1, name: "Diesel" };
        }
        const payload = {
          vehicleNo: this.vehicleNo,
          manufacturer: {
            id: this.manufacturer.id,
            name: this.manufacturer.name,
          },
          model: {
            id: this.model.id,
            name: this.model.name,
          },
          color: {
            id: this.color.id,
            name: this.color.name,
          },
          fuel: [
            {
              id: this.fuel.id,
              name: this.fuel.name,
            },
          ],
          year: this.year,
          rcbookExpiry: moment(this.RCExpiryDate).valueOf(),
          taxExpiry: moment(this.TaxExpiryDate).valueOf(),
          fitnessExpiry: moment(this.FtsExpiryDate).valueOf(),
          polutionExpiry: moment(this.PlnExpiryDate).valueOf(),
          insuranceExpiry: moment(this.InsExpiryDate).valueOf(),
          carriagepermit: moment(this.PmtExpiryDate).valueOf(),
        };
        if (this.buttonName === "submit") {
          this.loading = true;
          let vehicleArray = [];
          vehicleArray.push(payload);
          const response = await axios.post("vehicle/insert", { vehicle: vehicleArray });
          if (response.status === 200) {
            this.loading = false;
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000,
            });
            this.$emit("vehicle-updated");
            this.resetVehicleDetails();
          } else {
            this.loading = false;
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000,
            });
          }
        } else {
          payload.token = this.vehicleDetails.token;
          this.loading = true;
          const response = await axios.patch("vehicle/update", payload);
          if (response.status === 200) {
            this.loading = false;
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000,
            });
            this.$emit("vehicle-updated");
          } else if (response.status === 206) {
            this.loading = false;
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000,
            });
          }
        }
      }
    },
    cancelSubmit() {
      this.vehicleNo = "";
      this.manufacturer = "";
      this.model = "";
      this.color = "";
      this.fuel = "";
      this.year = "";
      this.$v.$reset();
      this.$emit("cancel-insert");
    },
  },
  computed: {
    vehicleNoError() {
      const errors = [];
      if (!this.$v.vehicleNo.$dirty) return errors;
      !this.$v.vehicleNo.required && errors.push("Vehicle No is required.");
      return errors;
    },
    manufacturerError() {
      const errors = [];
      if (!this.$v.manufacturer.$dirty) return errors;
      !this.$v.manufacturer.required && errors.push("Manufacturer is required.");
      return errors;
    },
    modelError() {
      const errors = [];
      if (!this.$v.model.$dirty) return errors;
      !this.$v.model.required && errors.push("Model is required.");
      return errors;
    },
    colorError() {
      const errors = [];
      if (!this.$v.color.$dirty) return errors;
      !this.$v.color.required && errors.push("Color is required.");
      return errors;
    },
    yearError() {
      const errors = [];
      if (!this.$v.year.$dirty) return errors;
      !this.$v.year.required && errors.push("Year is required.");
      return errors;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./vehicleInsert";
</style>
