<template>
  <div class="document-insert-content-modal">
    <div class="document-upload-modal" v-if="vehicleDetails">
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*,application/pdf" ref="rcUpload" @change="insertRC" type="file" class="input-blk" v-if="!vehicleDetails.trash" />
        <div class="image-upload-modal" @click="$refs.rcUpload.click()" v-if="rcImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="rcImage !== ''">
          <v-btn v-if="isPdf(rcImage)" @click="previewImage(rcImage)" small class="p-absolute">View PDF</v-btn>
          <img :src="rcImage" class="image-view-blk" v-if="!isPdf(rcImage)" @click="previewImage(rcImage)" />
          <object v-else :data="rcImage" width="100%" height="500px"></object>
        </div>
        <div class="image-name-blk">
          <span>RC Book Image</span>
          <div class="buttons" v-if="rcImage && vehicleDocument.rcbook && Object.prototype.hasOwnProperty.call(vehicleDocument.rcbook, 'verified')">
            <v-chip readonly outlined :color="vehicleDocument.rcbook.color">{{ vehicleDocument.rcbook.text }}</v-chip>
            <v-btn small color="#83b9e5" @click="$refs.rcUpload.click()" v-if="rcImage !== '' && !vehicleDetails.trash">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*,application/pdf" ref="interiorProof" @change="insertInterior" type="file" class="input-blk" v-if="!vehicleDetails.trash" />
        <div class="image-upload-modal" @click="$refs.interiorProof.click()" v-if="interiorImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="interiorImage !== ''">
          <v-btn v-if="isPdf(interiorImage)" @click="previewImage(interiorImage)" small class="p-absolute">View PDF</v-btn>
          <img :src="interiorImage" class="image-view-blk" v-if="!isPdf(interiorImage)" @click="previewImage(interiorImage)" />
          <object v-else :data="interiorImage" width="100%" height="500px"></object>
        </div>
        <div class="image-name-blk">
          <span>Interior Image</span>
          <div class="buttons" v-if="interiorImage && vehicleDocument.interior && Object.prototype.hasOwnProperty.call(vehicleDocument.interior, 'verified')">
            <v-chip readonly outlined :color="vehicleDocument.interior.color">{{ vehicleDocument.interior.text }}</v-chip>
            <v-btn small color="#83b9e5" @click="$refs.interiorProof.click()" v-if="interiorImage !== '' && !vehicleDetails.trash">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*,application/pdf" ref="exteriorProof" @change="insertExterior" type="file" class="input-blk" v-if="!vehicleDetails.trash" />
        <div class="image-upload-modal" @click="$refs.exteriorProof.click()" v-if="exteriorImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="exteriorImage !== ''">
          <v-btn v-if="isPdf(exteriorImage)" @click="previewImage(exteriorImage)" small class="p-absolute">View PDF</v-btn>
          <img :src="exteriorImage" class="image-view-blk" v-if="!isPdf(exteriorImage)" @click="previewImage(exteriorImage)" />
          <object v-else :data="exteriorImage" width="100%" height="500px"></object>
        </div>
        <div class="image-name-blk">
          <span>Exterior Image</span>
          <div class="buttons" v-if="exteriorImage && vehicleDocument.exterior && Object.prototype.hasOwnProperty.call(vehicleDocument.exterior, 'verified')">
            <v-chip readonly outlined :color="vehicleDocument.exterior.color">{{ vehicleDocument.exterior.text }}</v-chip>
            <v-btn small color="#83b9e5" @click="$refs.exteriorProof.click()" v-if="exteriorImage !== '' && !vehicleDetails.trash">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*,application/pdf" ref="insuranceProof" @change="insertInsurance" type="file" class="input-blk" v-if="!vehicleDetails.trash" />
        <div class="image-upload-modal" @click="$refs.insuranceProof.click()" v-if="insuranceImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="insuranceImage !== ''">
          <v-btn v-if="isPdf(insuranceImage)" @click="previewImage(insuranceImage)" small class="p-absolute">View PDF</v-btn>
          <img :src="insuranceImage" class="image-view-blk" v-if="!isPdf(insuranceImage)" @click="previewImage(insuranceImage)" />
          <object v-else :data="insuranceImage" width="100%" height="500px"></object>
        </div>
        <div class="image-name-blk">
          <span>Insurance</span>
          <div class="buttons" v-if="insuranceImage && vehicleDocument.insurance && Object.prototype.hasOwnProperty.call(vehicleDocument.insurance, 'verified')">
            <v-chip readonly outlined :color="vehicleDocument.insurance.color">{{ vehicleDocument.insurance.text }}</v-chip>
            <v-btn small color="#83b9e5" @click="$refs.insuranceProof.click()" v-if="insuranceImage !== '' && !vehicleDetails.trash">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>

      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*,application/pdf" ref="carriagePermitProof" @change="insertCarriagePermit" type="file" class="input-blk" v-if="!vehicleDetails.trash" />
        <div class="image-upload-modal" @click="$refs.carriagePermitProof.click()" v-if="carriagePermit === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="carriagePermit !== ''">
          <v-btn v-if="isPdf(carriagePermit)" @click="previewImage(carriagePermit)" small class="p-absolute">View PDF</v-btn>
          <img :src="carriagePermit" class="image-view-blk" v-if="!isPdf(carriagePermit)" @click="previewImage(carriagePermit)" />
          <object v-else :data="carriagePermit" width="100%" height="500px"></object>
        </div>
        <div class="image-name-blk">
          <span>Carriage Permit</span>
          <div class="buttons" v-if="carriagePermit && vehicleDocument.carriagepermit && Object.prototype.hasOwnProperty.call(vehicleDocument.carriagepermit, 'verified')">
            <v-chip readonly outlined :color="vehicleDocument.carriagepermit.color">{{ vehicleDocument.carriagepermit.text }}</v-chip>
            <v-btn small color="#83b9e5" @click="$refs.carriagePermitProof.click()" v-if="carriagePermit !== '' && !vehicleDetails.trash">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*,application/pdf" ref="taxValidityProof" @change="insertTaxValidity" type="file" class="input-blk" v-if="!vehicleDetails.trash" />
        <div class="image-upload-modal" @click="$refs.taxValidityProof.click()" v-if="taxValidity === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="taxValidity !== ''">
          <v-btn v-if="isPdf(taxValidity)" @click="previewImage(taxValidity)" small class="p-absolute">View PDF</v-btn>
          <img :src="taxValidity" class="image-view-blk" v-if="!isPdf(taxValidity)" @click="previewImage(taxValidity)" />
          <object v-else :data="taxValidity" width="100%" height="500px"></object>
        </div>
        <div class="image-name-blk">
          <span>Tax</span>
          <div class="buttons" v-if="taxValidity && vehicleDocument.tax && vehicleDocument.tax && Object.prototype.hasOwnProperty.call(vehicleDocument.tax, 'verified')">
            <v-chip readonly outlined :color="vehicleDocument.tax.color">{{ vehicleDocument.tax.text }}</v-chip>
            <v-btn small color="#83b9e5" @click="$refs.taxValidityProof.click()" v-if="taxValidity !== '' && !vehicleDetails.trash">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*,application/pdf" ref="fitnessProof" @change="insertFitness" type="file" class="input-blk" v-if="!vehicleDetails.trash" />
        <div class="image-upload-modal" @click="$refs.fitnessProof.click()" v-if="fitnessImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="fitnessImage !== ''">
          <v-btn v-if="isPdf(fitnessImage)" @click="previewImage(fitnessImage)" small class="p-absolute">View PDF</v-btn>
          <img :src="fitnessImage" class="image-view-blk" v-if="!isPdf(fitnessImage)" @click="previewImage(fitnessImage)" />
          <object v-else :data="fitnessImage" width="100%" height="500px"></object>
        </div>
        <div class="image-name-blk">
          <span>Fitness</span>
          <div class="buttons" v-if="fitnessImage && vehicleDocument.fitness && Object.prototype.hasOwnProperty.call(vehicleDocument.fitness, 'verified')">
            <v-chip readonly outlined :color="vehicleDocument.fitness.color">{{ vehicleDocument.fitness.text }}</v-chip>
            <v-btn small color="#83b9e5" @click="$refs.fitnessProof.click()" v-if="fitnessImage !== '' && !vehicleDetails.trash">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <!-- {{ PUC }} -->
        <input accept="image/*,application/pdf" ref="PUCProof" @change="insertPUC" type="file" class="input-blk" v-if="!vehicleDetails.trash" />
        <div class="image-upload-modal" @click="$refs.PUCProof.click()" v-if="PUC === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="PUC !== ''">
          <v-btn v-if="isPdf(PUC)" @click="previewImage(PUC)" small class="p-absolute">View PDF</v-btn>
          <img :src="PUC" class="image-view-blk" v-if="!isPdf(PUC)" @click="previewImage(PUC)" />
          <object v-else :data="PUC" width="100%" height="500px"></object>
        </div>
        <div class="image-name-blk">
          <span>PUC</span>
          <div class="buttons" v-if="PUC && vehicleDocument.pollution && Object.prototype.hasOwnProperty.call(vehicleDocument.pollution, 'verified')">
            <v-chip readonly outlined :color="vehicleDocument.pollution.color">{{ vehicleDocument.pollution.text }}</v-chip>
            <v-btn small color="#83b9e5" @click="$refs.PUCProof.click()" v-if="PUC !== '' && !vehicleDetails.trash">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
    </div>
    <div v-else class="no-vehicle-document">
      <span>No Data Available</span>
    </div>
    <v-dialog v-model="imagePreviewDialog" persistent max-width="90%">
      <v-card>
        <v-card-title class="headline"
          ><v-btn icon @click="imagePreviewDialog = false" class="close-btn">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title
        >

        <div class="invoice-content-modal">
          <img :src="imageToPreview" class="image-view-blk" v-if="!isPdf (imageToPreview)" style="padding: 10px;height: 80vh;object-fit: fill; max-width: 90%;" />
          <iframe v-else width="100%" :src="imageToPreview" style="height: 100vh; overflow: auto"></iframe>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
import moment from "moment";
export default {
  name: "DocumentInsert",
  props: {
    vehicleDetails: Object,
    resetTrigger: String,
  },
  data: () => {
    return {
      rcImage: "",
      interiorImage: "",
      exteriorImage: "",
      insuranceImage: "",
      carriagePermit: "",
      taxValidity: "",
      fitnessImage: "",
      PUC: "",
      baseUrl: "https://taski2020.s3.ap-south-1.amazonaws.com/",
      vehicleDocument: "",
      imageToPreview: "",
      imagePreviewDialog: false,
      InsExpiryDate: null,
      TaxExpiryDate: null,
      RCExpiryDate: null,
      FtsExpiryDate: null,
      PUCExpiryDate: null,
      selectedFile: "",
      imagePreviewDialog:false
    };
  },
  mounted() {
    this.getVehicleDetails({ load_data: true });
  },
  watch: {
    resetTrigger() {
      this.resetDetails({ load_data: this.resetTrigger == "cab-document-tab" ? true : false });
    },
  },
  methods: {
    isPdf(url) {
      return url && url.toLowerCase().includes(".pdf");
    },
    resetDetails(data) {
      let default_val = {
        color: "",
        text: "",
        verified: null,
        image: "",
      };
      this.vehicleDocument = {
        rcbook: { default_val },
        exterior: { default_val },
        interior: { default_val },
        insurance: { default_val },
        carriagepermit: { default_val },
        pollution: { default_val },
        tax: { default_val },
        fitness: { default_val },
      };
      this.InsExpiryDate = null;
      this.TaxExpiryDate = null;
      this.RCExpiryDate = null;
      this.FtsExpiryDate = null;
      this.PUCExpiryDate = null;
      this.selectedFile = "";
      this.CPExpiryDate = null;
      this.IDNumber = null;
      if (data.load_data) {
        this.getVehicleDetails();
      }
    },
    assignDetails() {
      this.rcImage = this.vehicleDocument.rcbook ? this.vehicleDocument.rcbook.image : "";
      this.interiorImage = this.vehicleDocument.interior ? this.vehicleDocument.interior.image : "";
      this.exteriorImage = this.vehicleDocument.exterior ? this.vehicleDocument.exterior.image : "";
      this.PUC = this.vehicleDocument.pollution ? this.vehicleDocument.pollution.image : "";
      this.PUCExpiryDate = this.vehicleDocument.pollution && this.vehicleDocument.pollution.expdate ? this.vehicleDocument.pollution.expdate : null;
      this.insuranceImage = this.vehicleDocument.insurance ? this.vehicleDocument.insurance.image : "";
      this.InsExpiryDate = this.vehicleDocument.insurance && this.vehicleDocument.insurance.expdate ? this.vehicleDocument.insurance.expdate : null;
      this.carriagePermit = this.vehicleDocument.carriagepermit ? this.vehicleDocument.carriagepermit.image : "";
      this.CPExpiryDate = this.vehicleDocument.carriagepermit && this.vehicleDocument.carriagepermit.expdate ? this.vehicleDocument.carriagepermit.expdate : null;
      this.taxValidity = this.vehicleDocument.tax ? this.vehicleDocument.tax.image : "";
      this.TaxExpiryDate = this.vehicleDocument.tax && this.vehicleDocument.tax.expdate ? this.vehicleDocument.tax.expdate : null;
      this.fitnessImage = this.vehicleDocument.fitness ? this.vehicleDocument.fitness.image : "";
      this.FtsExpiryDate = this.vehicleDocument.fitness && this.vehicleDocument.fitness.expdate ? this.vehicleDocument.fitness.expdate : null;
    },
    async getVehicleDetails() {
      if (this.vehicleDetails.token) {
        this.loading = true;
        const response = await axios.post("vehicle/details", { vehicleId: this.vehicleDetails.token });
        this.vehicleDocument = response.data.document;
        this.assignDetails();
        this.loading = false;
      }
    },
    async insertRC(event) {
      let files = event.target.files[0];
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          vehicleId: this.vehicleDetails.token,
          imageName: "rcBook",
          expdate: this.RCExpiryDate,
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("vehicle/document/update", formData);
        if (response.status === 200) {
          setTimeout(() => {
            this.rcImage = response.data.url;
          }, 500);
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        } else {
          this.$toasted.show(response.data, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertInterior(event) {
      debugger;
      let files = event.target.files[0];
      // this.idImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          vehicleId: this.vehicleDetails.token,
          imageName: "interior",
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("vehicle/document/update", formData);
        debugger;
        if (response.status === 200) {
          setTimeout(() => {
            this.interiorImage = response.data.url;
          }, 500);
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        } else {
          this.$toasted.show(response.data, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },

    previewImage(imageSrc) {
      this.imageToPreview = imageSrc;
      this.imagePreviewDialog = true;
    },
    async insertExterior(event) {
      let files = event.target.files[0];
      // this.licenceBackImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          vehicleId: this.vehicleDetails.token,
          imageName: "exterior",
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("vehicle/document/update", formData);
        if (response.status === 200) {
          setTimeout(() => {
            this.exteriorImage = response.data.url;
          }, 500);
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        } else {
          this.$toasted.show(response.data, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertInsurance(event) {
      let files = event.target.files[0];
      // this.licenceFrontImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          vehicleId: this.vehicleDetails.token,
          imageName: "insurance",
          expdate: this.InsExpiryDate,
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("vehicle/document/update", formData);
        if (response.status === 200) {
          setTimeout(() => {
            this.insuranceImage = response.data.url;
          }, 500);
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        } else {
          this.$toasted.show(response.data, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertCarriagePermit(event) {
      debugger;
      let files = event.target.files[0];
      // this.policeClearanceImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          vehicleId: this.vehicleDetails.token,
          imageName: "carriagepermit",
          expdate: this.CPExpiryDate,
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("vehicle/document/update", formData);
        if (response.status === 200) {
          setTimeout(() => {
            this.carriagePermit = response.data.url;
          }, 500);
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        } else {
          this.$toasted.show(response.data, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertTaxValidity(event) {
      let files = event.target.files[0];
      // this.policeClearanceImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          vehicleId: this.vehicleDetails.token,
          imageName: "tax",
          expdate: this.TaxExpiryDate,
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("vehicle/document/update", formData);
        if (response.status === 200) {
          setTimeout(() => {
            this.taxValidity = response.data.url;
          }, 500);
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        } else {
          this.$toasted.show(response.data, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertFitness(event) {
      let files = event.target.files[0];
      // this.policeClearanceImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          vehicleId: this.vehicleDetails.token,
          imageName: "fitness",
          expdate: this.FtsExpiryDate,
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("vehicle/document/update", formData);
        if (response.status === 200) {
          setTimeout(() => {
            this.fitnessImage = response.data.url;
          }, 500);
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        } else {
          this.$toasted.show(response.data, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertPUC(event) {
      let files = event.target.files[0];
      // this.policeClearanceImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          vehicleId: this.vehicleDetails.token,
          imageName: "pollution",
          expdate: this.PUCExpiryDate,
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("vehicle/document/update", formData);
        if (response.status === 200) {
          setTimeout(() => {
            this.PUC = response.data.url;
          }, 500);
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        } else {
          this.$toasted.show(response.data, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/components/vehicle-management/vehicle-document/vehicleDocument";
.invoice-content-modal {
  text-align: center;
  width: 100%;
  z-index: 100;
  .image-preview {
    width: 100%;
    object-fit: contain;
    padding: 10px;
  }
}
.headline {
  display: flex;
  justify-content: flex-end;
  .v-btn {
    background-color: #F44336;
    color: white;
  }
}
</style>
