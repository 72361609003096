<template>
  <div class="outer-blk">
    <div class="tab-blk">
      <div class="tab-content-modal">
        <div class="user-content-blk">
          <span @click="getRegisterForm" :class="{'selected-tab-blk':selectedTab === 'Account'}">Account</span>
        </div>
        <v-btn color="#eea004" class="logout-button-blk" @click="logout">Logout</v-btn>
      </div>
      <div class="tab-inner-blk" v-if="vendorStatus === '1'">
        <div class="tab-content-blk" v-for="(data,index) in tabItems" :key="index" @click="selectTab(data)"
             :class="{'selected-tab-blk':data === selectedTab,'not-approved-blk':vendorStatus === '0' || vendorStatus === '2'}">
          <span>{{ data }}</span>
        </div>
      </div>

    </div>
    <div class="dashboard-content-blk">

      <ToBeAssigned v-if="selectedTab === 'To Be Assigned'"></ToBeAssigned>

      <DriverDashboard v-if="selectedTab === 'Driver Management'"></DriverDashboard>

      <TripList v-if="selectedTab === 'Trip'"></TripList>

      <VehicleDashboard v-if="selectedTab === 'Vehicle Management'"></VehicleDashboard>

      <AdminDashboard v-if="selectedTab === 'Admin Management'"></AdminDashboard>

      <Fare v-if="selectedTab === 'Fare'"></Fare>

<!--      <PeriodicalReports v-if="selectedTab === 'Reports'"></PeriodicalReports>-->

      <Reports v-if="selectedTab === 'Reports'"></Reports>

            <ReRegister v-if="selectedTab === 'Account'"></ReRegister>
    </div>
    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import instance from "@/axios/axios-global";
import ToBeAssigned from "@/components/assign/to-be-assigned/ToBeAssigned";
import DriverDashboard from "@/components/driver-management/driver-dashboard/DriverDashboard";
import TripList from "@/components/Trips/trip-list/TripList";
import VehicleDashboard from "@/components/vehicle-management/vehicle-dashboard/VehicleDashboard";
import Fare from "@/components/fares/Fare";
import Register from "@/components/Register";
import ReRegister from "@/components/account/ReRegister";
import Reports from "../reports/Reports";
import Confirm from "@/components/common/Confirm";
import AdminDashboard from "@/components/admin/AdminDashboard";
// import PeriodicalReports from "@/components/reports/PeriodicalReports";

export default {
  name: "Dashboard",
  components: {
    AdminDashboard,
    Confirm, ReRegister, Register, Fare, VehicleDashboard, TripList, DriverDashboard, ToBeAssigned, Reports
  },
  data: () => {
    return {
      tabItems: ['Trip', 'To Be Assigned', 'Driver Management', 'Vehicle Management', 'Admin Management','Fare', 'Reports'],
      selectedTab: 'To Be Assigned',
      vendorName: '',
      vendorNumber: '',
      vendorStatus: ''
    }
  },
  mounted() {
    this.vendorName = localStorage.getItem('vendorName')
    this.vendorNumber = localStorage.getItem('vendorNumber')
    this.vendorStatus = localStorage.getItem('vendorStatus')
    if (this.vendorStatus === '0' || this.vendorStatus === '2') {
      this.selectedTab = 'Account'
    }
  },
  methods: {
    selectTab(data) {
      if (this.vendorStatus === '1') {
        this.selectedTab = data
      } else if (this.vendorStatus === '0' || this.vendorStatus === '2') {
        this.selectedTab = 'Account'
      }
    },
    async logout() {
      let confirmTitle = "Confirm Logout";
      let confirmText = "Please Confirm to Logout";
      let confirmIcon = "fas fa-exclamation"
      if (await this.$refs.confirm.open(confirmTitle, confirmText, {
        icon: confirmIcon,
        color: 'red',
        buttonName: 'Logout',
      })) {
        instance.defaults.headers.common['token'] = "";

        localStorage.removeItem('vendorLoginToken');
        localStorage.removeItem('vendorName');
        localStorage.removeItem('vendorNumber');
        localStorage.removeItem('vendorStatus');

        await this.$router.push('/login')
      }
    },
    getRegisterForm() {
      this.selectedTab = 'Account'
    }
  }
}
</script>

<style scoped lang="scss">
@import "./dashboard.scss";

</style>