<template>
  <div class="profile-modal">
    <div class="profile-tab-blk" v-if="driverDetails.token">
      <div class="details-tab-modal">
        <v-card elevation="2" class="profile-blk">
          <div class="driver-details-modal" v-if="driverDetails">
            <div class="driver-image-blk text-center" v-if="driverDetails">
              <img :src="driverDetails.image || ''" class="image-blk" />
            </div>
            <div class="name-blk text-center">
              <span>{{ driverDetails.name }}</span>
            </div>
            <div class="text-center">
              <span><i class="fas fa-phone-alt"></i>{{ driverDetails.mobile }}</span>
            </div>
            <div class="text-center color-gray-blk" v-if="driverDetails">
              <span v-if="driverDetails"><b>Licence No:</b>{{ driverDetails.document && driverDetails.document.licensefront.no ? driverDetails.document.licensefront.no : "-" }}</span>
            </div>
            <div class="text-center color-gray-blk" v-if="driverDetails">
              <span><b>Licence Expiry Date:</b>{{ getExpDate(driverDetails) }}</span>
            </div>
            <div v-if="driverDetails.vehicles">
              <div class="text-center vehicle-list-modal" v-if="driverDetails.vehicles.length > 0">
                <div class="vehicle-list-blk" v-for="(data, index) in driverDetails.vehicles" :key="index">
                  <span :class="{'trash-vehicle':data.trash === 1}">{{ data.vehicleno }}</span>
                  <span class="margin-blk" @click="unlinkVehicle(data)"><i class="fas fa-minus-circle"></i></span>
                </div>
              </div>
            </div>
            <div class="text-center no-vehicle-modal" v-if="driverDetails.vehicles && driverDetails.vehicles.length === 0">
              <span class="vehicle-text">No Vehicle Assigned</span>
            </div>

            <div class="edit-button-modal">
              <v-btn @click="remarksModel = true" :color="driverDetails.trash ? 'green' : '#ed5e5e'">{{ !driverDetails.trash ? "Deactivate" : "Activate" }}</v-btn>
              <v-btn color="#facc15" @click="editDriver()" v-if="!driverDetails.trash">Edit</v-btn>
            </div>
            <Loading v-if="loading"></Loading>
            <Confirm ref="confirm"></Confirm>
            <DeactivationModal
              v-if="remarksModel"
              :title="driverDetails.trash ? 'Activate' : 'Deactivate'"
              :show.sync="remarksModel"
              :onConfirm="driverDetails.trash ? activateDriver : deactivateDriver"
              @dialog-closed="remarksModel = false">
            </DeactivationModal>
          </div>
        </v-card>
      </div>
      <div class="profile-tab-modal" v-if="!driverDetails.trash">
        <v-card class="vehicle-assign-modal" elevation="2">
          <span class="vehicle-assign-header">Vehicle Assign</span>
          <v-autocomplete v-model="vehicle" placeholder="Vehicle" label="Vehicle" outlined dense :items="vehicleList" return-object item-text="vehicleNo" hide-details></v-autocomplete>
          <div class="assign-button-blk">
            <v-btn color="#eea004" :disabled="!vehicle" @click="assignVehicle">Assign</v-btn>
          </div>
        </v-card>
      </div>
    </div>
    <div v-else class="no-driver-profile">No data Available</div>
    <Loading v-if="loading"></Loading>
    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import Confirm from "@/components/common/Confirm";
import DeactivationModal from "../../modal/DeactivationModal.vue";
import * as moment from "moment";
export default {
  name: "DriverProfile",
  components: { Confirm, Loading, DeactivationModal },
  props: {
    driverDetails: Object,
  },
  data: () => {
    return {
      vehicle: "",
      vehicleList: [],
      loading: false,
      baseUrl: "https://taski2020.s3.ap-south-1.amazonaws.com/",
      remarksModel: false,
    };
  },
  watch: {
    vehicle() {
      if (this.vehicle === null) {
        this.vehicle = "";
      }
    },
  },
  mounted() {
    this.getVehicleList();
    this.vehicle = "";
  },
  methods: {
    async getVehicleList() {
      const response = await axios.post("vehicle/list");
      this.vehicleList = response.data;
      if (this.vehicleList && this.vehicleList.length > 0) {
        this.vehicleList = this.vehicleList.filter((vehicle) => {
          if (!vehicle.trash) {
            return vehicle;
          }
        });
      }
    },
    async assignVehicle() {
      const payload = {
        vehicleNo: this.vehicle.vehicleNo,
        driver: {
          token: this.driverDetails.token,
          name: this.driverDetails.name,
        },
      };
      let confirmTitle = "Confirm Assign";
      let confirmText = `Please confirm to Assign ${this.vehicle.vehicleNo} to ${this.driverDetails.name}`;
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        this.loading = true;
        try {
          const response = await axios.patch("driver/vehicle/link", payload);
          if (response.status === 200) {
            this.driverDetails.vehicles.push({ vehicleno: this.vehicle.vehicleNo, id: this.vehicle.token });
            this.vehicle = "";
            this.loading = false;
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000,
            });
          } else if (response.status === 206) {
            this.loading = false;
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000,
            });
          }
        } catch (error) {
          this.loading = false;
        }
      }
    },
    editDriver() {
      this.$emit("edit-driver");
    },
    emitAction(driverDetails) {
      this.$emit("driver-updated", driverDetails);
    },
    async unlinkVehicle(vehicle) {
      let confirmTitle = "Confirm UnAssign";
      let confirmText = `Please Confirm to Unassign ${vehicle.vehicleno} from ${this.driverDetails.name}`;
      let confirmIcon = "fas fa-exclamation";
      if (
        await this.$refs.confirm.open(confirmTitle, confirmText, {
          icon: confirmIcon,
          color: "red",
        })
      ) {
        this.loading = true;
        try {
          const response = await axios.patch("driver/vehicle/unLink", {
            vehicleToken: vehicle.id,
            driverToken: this.driverDetails.token,
          });
          if (response.status === 200) {
            this.loading = false;
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000,
            });
            const index = this.driverDetails.vehicles.findIndex((item) => item.id === vehicle.id);
            this.driverDetails.vehicles.splice(index, 1);
          }
        } catch (error) {
          this.loading = false;
        }
      }
    },
    getExpDate(data) {
      return data.document && data.document.licensefront && data.document.licensefront.expdate ? moment(data.document.licensefront.expdate).format("DD-MM-YYYY") : "-";
    },
    async deactivateDriver(remarks) {
      if (!remarks) {
        return this.$toasted.show("Remarks Required", {
          position: "bottom-center",
          duration: 4000,
        });
      }
      this.loading = true;
      const index = this.driverDetails.reason.length + 1 || 1;
      const payload = {
        driverId: this.driverDetails.token,
        id: index,
        date: moment.now(),
        reason: remarks,
        remarksBy: {
          name: localStorage.getItem("vendorName"),
        },
      };
      const response = await axios.post("driver/deactivateDriver", payload);
      if (response.status === 200) {
        this.loading = false;
        this.remarksModel = false;
        this.$toasted.show(response.data, {
          position: "bottom-center",
          duration: 4000,
        });
        this.$emit("driver-updated");
      }
    },
    async activateDriver(remarks) {
      if (!remarks) {
        return this.$toasted.show("Remarks required", {
          position: "bottom-center",
          duration: 4000,
        });
      }
      this.loading = true;
      const index = this.driverDetails.reason.length + 1 || 1;
      const payload = {
        driverId: this.driverDetails.token,
        id: index,
        date: moment.now(),
        reason: remarks,
        remarksBy: {
          name: localStorage.getItem("vendorName"),
        },
      };
      const response = await axios.patch("driver/activate", payload);
      if (response.status === 200) {
        this.$emit("driver-updated");
        this.loading = false;
        this.remarksModel = false;
        this.$toasted.show(response.data, {
          position: "bottom-center",
          duration: 4000,
        });
      } else if (response.status === 206) {
        this.loading = false;
        this.$toasted.show(response.data, {
          position: "bottom-center",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./driverProfile";
.trash-vehicle{
  text-decoration: line-through;
  text-decoration-color: red;
}
</style>
