<template>
  <div class="document-insert-content-modal">
    <div class="document-upload-modal" v-if="driverDetails.token">
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*,application/pdf" ref="photoUpload" @change="insertPhoto" type="file" class="input-blk" v-if="!driverDetails.trash" />
        <div class="image-upload-modal" @click="$refs.photoUpload.click()" v-if="photoImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="photoImage !== ''">
          <v-btn v-if="isPdf(photoImage)" @click="previewImage(photoImage)" small class="p-absolute">View PDF</v-btn>
          <img :src="photoImage" class="image-view-blk" v-if="!isPdf(photoImage)" @click="previewImage(photoImage)" />
          <object v-else :data="photoImage" width="100%" height="500px"></object>
        </div>
        <div class="image-name-blk">
          <span>Photo</span>
          <div class="buttons" v-if="photoImage && documents.photo.hasOwnProperty('verified')">
            <v-chip readonly outlined :color="documents.photo.color">{{ documents.photo.text }}</v-chip>
            <v-btn small color="#83b9e5" @click="$refs.photoUpload.click()" v-if="photoImage !== '' && !driverDetails.trash">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*,application/pdf" ref="idProof" @change="insertIdProof" type="file" class="input-blk" v-if="!driverDetails.trash" />
        <div class="image-upload-modal" @click="$refs.idProof.click()" v-if="idImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="idImage !== ''">
          <v-btn v-if="isPdf(idImage)" @click="previewImage(idImage)" small class="p-absolute">View PDF</v-btn>
          <img :src="idImage" class="image-view-blk" v-if="!isPdf(idImage)" @click="previewImage(idImage)"/>
          <object v-else :data="idImage" width="100%" height="500px"></object>
        </div>
        <div class="image-name-blk">
          <span>ID Proof</span>
          <div class="buttons" v-if="idImage && Object.prototype.hasOwnProperty.call(documents.idproof, 'verified')">
            <v-chip readonly outlined :color="documents.idproof.color">{{ documents.idproof.text }}</v-chip>
            <v-btn small color="#83b9e5" @click="$refs.idProof.click()" v-if="idImage !== '' && !driverDetails.trash">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <!--      <v-card class="photo-insert-modal" elevation="2" >-->
      <!--        <input accept="image/*" ref="panProof" @change="insertPan" type="file" class="input-blk">-->
      <!--        <div class="image-upload-modal" @click="$refs.panProof.click()" v-if="panImage === ''">-->
      <!--          <i class="fas fa-images"></i>-->
      <!--          <span>Upload Image</span>-->
      <!--        </div>-->
      <!--        <div class="image-upload-modal" v-if="panImage !== ''">-->
      <!--          <img :src="panImage" class="image-view-blk">-->
      <!--        </div>-->
      <!--        <span class="image-name-blk">Pan Card</span>-->
      <!--      </v-card>-->
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*,application/pdf" ref="licenceFront" @change="insertLicenceFront" type="file" class="input-blk" v-if="!driverDetails.trash" />
        <div class="image-upload-modal" @click="$refs.licenceFront.click()" v-if="!licenceFrontImage">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="licenceFrontImage !== ''">
          <v-btn v-if="isPdf(licenceFrontImage)" @click="previewImage(licenceFrontImage)" small class="p-absolute">View PDF</v-btn>
          <img :src="licenceFrontImage" v-if="!isPdf(licenceFrontImage)" @click="previewImage(licenceFrontImage)" class="image-view-blk" />
          <object v-else :data="licenceFrontImage" width="100%" height="500px"></object>
        </div>
        <div class="image-name-blk">
          <span>Licence Front</span>
          <div class="buttons" v-if="licenceFrontImage && Object.prototype.hasOwnProperty.call(documents.licensefront, 'verified')">
            <v-chip readonly outlined :color="documents.licensefront.color">{{ documents.licensefront.text }}</v-chip>
            <v-btn small color="#83b9e5" @click="$refs.licenceFront.click()" v-if="licenceFrontImage !== '' && !driverDetails.trash">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="licenceBack" @change="insertLicenceBack" type="file" class="input-blk" v-if="!driverDetails.trash" />
        <div class="image-upload-modal" @click="$refs.licenceBack.click()" v-if="licenceBackImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="licenceBackImage !== ''">
          <v-btn v-if="isPdf(licenceBackImage)" @click="previewImage(licenceBackImage)" small class="p-absolute">View PDF</v-btn>
          <img :src="licenceBackImage" class="image-view-blk" v-if="!isPdf(licenceBackImage)" @click="previewImage(licenceBackImage)" />
          <object v-else :data="licenceBackImage" width="100%" height="500px"></object>
        </div>
        <div class="image-name-blk">
          <span>Licence Back</span>
          <div class="buttons" v-if="licenceBackImage && Object.prototype.hasOwnProperty.call(documents.licenseback, 'verified')">
            <v-chip readonly outlined :color="documents.licenseback.color">{{ documents.licenseback.text }}</v-chip>
            <v-btn small color="#83b9e5" @click="$refs.licenceBack.click()" v-if="licenceBackImage !== '' && !driverDetails.trash">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="policeClearance" @change="insertPoliceClearance" type="file" class="input-blk" v-if="!driverDetails.trash" />
        <div class="image-upload-modal" @click="$refs.policeClearance.click()" v-if="policeClearanceImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="policeClearanceImage !== ''">
          <v-btn v-if="isPdf(policeClearanceImage)" @click="previewImage(policeClearanceImage)" small class="p-absolute">View PDF</v-btn>
          <img :src="policeClearanceImage" class="image-view-blk" v-if="!isPdf(policeClearanceImage)" @click="previewImage(policeClearanceImage)" />
          <object v-else :data="policeClearanceImage" width="100%" height="500px"></object>
        </div>
        <div class="image-name-blk">
          <span>Police Clearance</span>
          <div class="buttons" v-if="policeClearanceImage && Object.prototype.hasOwnProperty.call(documents.policeclearance, 'verified')">
            <v-chip readonly outlined :color="documents.policeclearance.color">{{ documents.policeclearance.text }}</v-chip>
            <v-btn small color="#83b9e5" @click="$refs.policeClearance.click()" v-if="policeClearanceImage !== '' && !driverDetails.trash">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
    </div>
    <div v-else class="no-driver-document">No data Available</div>
    <v-dialog v-model="imagePreviewDialog" persistent max-width="90%">
      <v-card>
        <v-card-title class="headline"
          ><v-btn icon @click="imagePreviewDialog = false" class="close-btn">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title
        >

        <div class="invoice-content-modal">
          <img :src="imageToPreview" class="image-view-blk" v-if="!isPdf (imageToPreview)" style="padding: 10px;height: 80vh;object-fit: fill; max-width: 90%;" />
          <iframe v-else width="100%" :src="imageToPreview" style="height: 100vh; overflow: auto"></iframe>
        </div>
      </v-card>
    </v-dialog>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
import * as moment from "moment";
import Loading from "@/components/common/Loading";

export default {
  name: "DocumentInsert",
  components: { Loading },
  props: {
    driverDetails: Object,
    resetTrigger: String,
  },
  data: () => {
    return {
      photoImage: "",
      idImage: "",
      licenceFrontImage: "",
      licenceBackImage: "",
      policeClearanceImage: "",
      documents: "",
      loading: false,
      IDExpiryDate: null,
      LncExpiryDate: null,
      POCExpiryDate: null,
      imageToPreview: "",
      imagePreviewDialog: false,
    };
  },
  methods: {
    validateExpiry(model, reference, open) {
      if (!model) {
        this.$toasted.show("Please select the expiry date first", {
          position: "bottom-center",
          duration: 4000,
        });
        return false;
      } else {
        if (open) {
          this.$refs[reference].click();
        } else {
          return true;
        }
      }
    },
    resetDetails(data) {
      this.loading = true;
      let default_val = {
        color: "",
        text: "",
        verified: null,
        image:""
      };
      this.documents = {
        photo: default_val,
        idproof: default_val,
        licencefront: default_val,
        licenseback: default_val,
        policeclearance: default_val,
      };
      this.IDExpiryDate = "";
      this.LncExpiryDate = "";
      this.POCExpiryDate = "";
      if (data.load_data) {
        this.getDriverDetails();
      }
    },
    assignDetails() {
      this.photoImage = this.documents.photo.image;
      this.idImage = this.documents.idproof.image;
      this.IDExpiryDate = this.documents.idproof.expdate ? this.documents.idproof.expdate : "";
      this.licenceFrontImage = this.documents.licensefront.image;
      this.LncExpiryDate = this.documents.licensefront.expdate ? this.documents.licensefront.expdate : "";
      this.licenceBackImage = this.documents.licenseback.image;
      this.policeClearanceImage = this.documents.policeclearance.image;
      this.POCExpiryDate = this.documents.policeclearance.expdate ? this.documents.policeclearance.expdate : "";
    },
    previewImage(imageSrc) {
      this.imageToPreview = imageSrc;
      this.imagePreviewDialog = true;
    },
    isPdf(url) {
      return url && url.toLowerCase().includes(".pdf");
    },
    async getDriverDetails() {
      if (this.driverDetails.token) {
        this.loading = true;
        const response = await axios.post("driver/details", { driverId: this.driverDetails.token });
        this.documents = response.data.document;
        this.assignDetails();
        this.loading = false;
      }
    },
    async validateDocument(document) {
      document.token = this.driverDetails.token;
      const response = await axios.post("driver/document/verify", document);
      if (response && response.status === 200) {
        this.$toasted.show(response.data, {
          position: "bottom-center",
          duration: 4000,
        });
        this.getDriverDetails();
      } else {
        this.$toasted.show(response.data, {
          position: "bottom-center",
          duration: 4000,
        });
      }
    },
    async insertPhoto(event) {
      let files = event.target.files[0];
      // this.photoImage = URL.createObjectURL(files)
      this.loading = true;
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverDetails.token,
          imageName: "photo",
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("driver/document/update", formData);
        this.loading = false;
        if (response.status === 200) {
          setTimeout(() => {
            this.photoImage = response.data.url;
          }, 500);
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertIdProof(event) {
      let files = event.target.files[0];
      this.loading = true;
      // this.idImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverDetails.token,
          imageName: "idProof",
          expdate: this.IDExpiryDate,
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("driver/document/update", formData);
        this.loading = false;
        if (response.status === 200) {
          setTimeout(() => {
            this.idImage = response.data.url;
          }, 500);
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    // async insertPan(event) {
    //   let files = event.target.files[0]
    //   this.panImage = URL.createObjectURL(files)
    // },
    async insertLicenceFront(event) {
      let files = event.target.files[0];
      this.loading = true;
      // this.licenceFrontImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverDetails.token,
          imageName: "licenceFront",
          licenceNo: this.licenceNo,
          expdate: this.LncExpiryDate,
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("driver/document/update", formData);
        this.loading = false;
        if (response.status === 200) {
          setTimeout(() => {
            this.licenceFrontImage = response.data.url;
          }, 500);
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertLicenceBack(event) {
      let files = event.target.files[0];
      this.loading = true;
      // this.licenceBackImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverDetails.token,
          imageName: "licenceBack",
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("driver/document/update", formData);
        this.loading = false;
        if (response.status === 200) {
          setTimeout(()=>{
            this.licenceBackImage = response.data.url;
          },500)
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertPoliceClearance(event) {
      let files = event.target.files[0];
      this.loading = true;
      // this.policeClearanceImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverDetails.token,
          imageName: "policeClearance",
          expdate: this.POCExpiryDate,
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("driver/document/update", formData);
        this.loading = false;
        if (response.status === 200) {
          setTimeout(() => {
            this.policeClearanceImage = response.data.url;
          }, 500);
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
  },
  mounted() {
    this.resetDetails({ load_data: true });
  },
  watch: {
    resetTrigger() {
      this.resetDetails({ load_data: this.resetTrigger == "document-tab" ? true : false });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./documentInsert";
.invoice-content-modal {
  text-align: center;
  width: 100%;
  z-index: 100;
  .image-preview {
    width: 100%;
    object-fit: contain;
    padding: 10px;
  }
}
.headline {
  display: flex;
  justify-content: flex-end;
  .v-btn {
    background-color: #f44336;
    color: white;
  }
}
</style>
