<template>
 <div class="vehicle-list-content-modal">
  <div class="vehicle-header-blk">
   <v-btn small color="#facc15" @click="insertVehicle">Add Vehicle</v-btn>
  </div>
  <div class="vehicle-content-modal">
   <div v-if="vehicleList.length > 0">
    <div class="vehicle-list-content-blk" v-for="(data, index) in vehicleList" :key="index" @click="selectVehicle(data)" :class="{ 'selected-vehicle-blk': data.token === vehicleId }">
     <div class="vehicle-image-blk">
      <div class="vehicle-image-outer-blk">
       <img src="../../../assets/images/car-avatar.webp" class="vehicle-img-blk" />
      </div>
     </div>
     <div class="vehicle-details-blk">
      <span class="vehicle-name-blk">{{ data.vehicleNo }}</span>
      <span>{{ data.manufacturer }}</span>
      <span>{{ data.model }}</span>
     </div>
     <span class="vehicle-status-blk" v-if="data.trash !== 1">Active</span>
     <span class="vehicle-deactive-status" v-else>Deactivated</span>
    </div>
   </div>

   <div v-else class="no-data-blk">
    <span>No Vehicle, Please Add</span>
   </div>
  </div>
  <div class="pagination">
   <v-pagination v-model="page" :length="parseInt(totalCount / 20) + 1" :total-visible="4"></v-pagination>
  </div>
  <v-dialog width="800" v-model="bulkDialogView">
   <VehicleBulkUpload :vehicleData="vehicleData" v-if="bulkDialogView" @vehicle-bulk="vehicleInsert"></VehicleBulkUpload>
  </v-dialog>
 </div>
</template>

<script>
import XLSX from "xlsx";
import VehicleBulkUpload from "@/components/vehicle-management/VehicleBulkUpload";

export default {
 name: "VehicleList",
 components: { VehicleBulkUpload },
 props: {
  vehicleList: Array,
  items: Number,
  token: String,
  totalCount: Number,
 },
 watch: {
  token() {
   this.vehicleId = this.token;
  },
  page() {
   this.loadMore();
  },
 },
 mounted() {
  setTimeout(() => {
   if (this.vehicleList.length > 0) {
    this.vehicleId = this.vehicleList[0].token;
   }
  }, 300);
 },
 data: () => {
  return {
   vehicleId: 0,
   bulkDialogView: false,
   vehicleData: [],

   page: 1,
  };
 },
 methods: {
  insertVehicle() {
   this.$emit("insert-vehicle");
  },
  selectVehicle(data) {
   this.vehicleId = data.token;
   this.$emit("select-vehicle", data);
  },
  loadMore() {
   console.log("loadMore");
   this.$emit("load-more-vehicles", this.page);
  },
  // onScroll() {
  //  const container = document.getElementById("vehicleContainer");
  //   if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
  //    this.$emit("load-more-vehicles");
  //   }
  // },
  addMultipleVehicle(event) {
   this.file = event.target.files ? event.target.files[0] : null;
   if (this.file) {
    const reader = new FileReader();
    reader.onload = (e) => {
     const bstr = e.target.result;
     const wb = XLSX.read(bstr, {
      type: "binary",
      cellDates: true,
      cellNF: false,
      cellText: false,
     });
     const wsname = wb.SheetNames[0];
     const ws = wb.Sheets[wsname];
     this.vehicleData = XLSX.utils.sheet_to_json(ws, { header: 0 });
     this.vehicleData.map((data) => {
      data.year = data.year.toString();
     });
     this.bulkDialogView = true;
     this.$refs.vehicleInsert.value = "";
    };
    reader.readAsBinaryString(this.file);
   }
  },
  downloadSample() {},
  vehicleInsert(vehicle) {
   this.bulkDialogView = false;
   vehicle.array.map((data) => {
    const index = vehicle.idArray.findIndex((item) => item.vehicleNo === data.vehicleNo);
    if (index !== -1) {
     this.$emit("insert-cab", {
      id: vehicle.idArray[index].id,
      vehicleNo: vehicle.vehicleNo,
      manufacturer: vehicle.manufacturer.name,
      model: vehicle.model.name,
     });
    }
   });
  },
 },
};
</script>

<style scoped lang="scss">
@import "./vehicleList";

.pagination {
 display: block;
 bottom: 0;
 width: 100%;
 background: white;
 padding-top: 10px;
}
</style>
