<template>
  <div class="driver-cab-blk">
    <div class="driver-cab-modal">
      <div class="driver-blk">
        <h2>Driver</h2>
        <div class="driver-input-blk">
          <v-text-field
              v-model="driverName"
              placeholder="Driver"
              label="Driver"
              dense
              outlined
              :disabled="disableStatus === true"
              :error-messages="driverNameError"
              @blur="$v.driverName.$touch()"
              @input="$v.driverName.$touch()"
          ></v-text-field>
          <v-text-field
              v-model="number"
              placeholder="Number"
              label="Number"
              dense
              outlined
              disabled
          ></v-text-field>
        </div>
      </div>
      <div class="cab-blk">
        <h2>Cab</h2>
        <div class="vehicle-input-blk">
          <v-text-field
              v-model="vehicleNo"
              placeholder="Vehicle Number"
              label="Vehicle Number"
              dense
              outlined
              :error-messages="vehicleNoError"
              @blur="$v.vehicleNo.$touch()"
              @input="$v.vehicleNo.$touch()"
              @keydown="nameKeydown($event)"
              @paste="pasteKey($event)"
              @keydown.space.prevent
          >
          </v-text-field>
          <v-autocomplete
              v-model="model"
              placeholder="Model"
              label="Model"
              dense
              outlined
              :items="modelList"
              item-text="name"
              return-object
              :error-messages="modelError"
              @blur="$v.model.$touch()"
              @input="$v.model.$touch()"
          >

          </v-autocomplete>
        </div>
      </div>
    </div>
    <div class="button-blk">
      <v-btn color="#df5f55" @click="closeAddDialog">Close</v-btn>
      <v-btn color="#eea004" @click="submit">Submit</v-btn>
    </div>

    <Loading v-if="loading"></Loading>

    <v-dialog
        width="500"
        v-model="dialogView"
    >
      <v-card class="card-modal-view">
        <span>Vehicle belongs to another vendor,please contact taSki to assign this vehicle</span>
        <div class="card-button-blk">
          <v-btn small color="#eea004" @click="closeDVehicleDialog">OK</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
  name: "DriverCabInsert",
  components: {Loading},
  mixins: [validationMixin],
  validations() {
    return {
      driverName: {required},
      vehicleNo: {required},
      model: {required},
    }
  },
  props: {
    driverNumber: String,
    name: String,
    bigId: Number,
    disableStatus: Boolean,
    modelList: Array,
    currentState: Number,
    currentCity: Number
  },
  watch: {
    driverNumber() {
      this.driverName = this.name
      this.number = this.driverNumber
    },
    vehicleNo() {
      this.vehicleNo = this.vehicleNo.toUpperCase()
    },
  },

  mounted() {
    this.number = this.driverNumber
    this.driverName = this.name
  },
  data() {
    return {
      driverName: '',
      number: '',
      vehicleNo: '',
      model: '',

      filterData: '',

      loading: false,

      dialogView: false,
    }
  },
  methods: {
    nameKeydown(e) {
      if (/[^A-Z0-9]/gi.test(e.key)) {
        e.preventDefault();
      }
    },
    pasteKey(event) {
      let main_text = event.clipboardData.getData("text");
      main_text = main_text.replace(/[^A-Z0-9]/gi,'')
      setTimeout(() => {
        this.vehicleNo = main_text
      },0)
    },
    closeAddDialog() {
      this.vehicleNo = ''
      this.model = ''
      this.$v.$reset()
      this.$emit('close-add-dialog')
    },

    async submit() {
      this.$v.$touch()
      if (this.$v.$anyError) {
      } else {
        this.loading = true
        try {
          const payload = {
            driver: {
              name: this.driverName,
              number: this.number,
              city: this.currentCity,
              state: this.currentState
            },
            cab: {
              vehicleNo: this.vehicleNo,
              model: this.model,
              color: {id: 1, name: "White"},
              fuel: {id: 1, name: "Diesel"},
              year: 2018
            },
            tripId: this.bigId,
            driverConfirm: true,
          }

          const response = await axios.post('/router/tripAssign', payload)
          if (response.status === 200) {
            this.loading = false
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 5000
            });
            const emitDetails = {
              name: this.driverName,
              number: this.number,
              vehicleNo: this.vehicleNo
            }
            this.$emit('assign-driver', emitDetails)
          } else if(response.status === 206) {
            this.dialogView = true
            this.loading = false
            // this.$emit('close-add-dialog')
            // this.driverName = ''
            // this.number = ''
            // this.vehicleNo = ''
            // this.model = ''
            // this.$v.$reset()
            // this.$toasted.show(response.data, {
            //   position: "bottom-center",
            //   duration: 4000
            // });
          }
        } catch (error) {
          this.loading = false
          // this.$toasted.show(error.response.data, {
          //   position: "bottom-center",
          //   duration: 4000
          // });
        }
      }
    },
    closeDVehicleDialog() {
      this.dialogView = false
    }
  },
  computed: {
    driverNameError() {
      const errors = []
      if (!this.$v.driverName.$dirty) return errors
      !this.$v.driverName.required && errors.push('Driver Name is required.')
      return errors
    },
    vehicleNoError() {
      const errors = []
      if (!this.$v.vehicleNo.$dirty) return errors
      !this.$v.vehicleNo.required && errors.push('Vehicle No is required.')
      return errors
    },
    modelError() {
      const errors = []
      if (!this.$v.model.$dirty) return errors
      !this.$v.model.required && errors.push('Model is required.')
      return errors
    },
  },
}
</script>

<style scoped lang="scss">
@import "./driverCabInsert";
</style>